import { Component, ContextType, Suspense } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import {
  FeaturedDoctor,
  FeaturedHospital,
  FeaturedSpeciality,
  FeaturedTreatment,
  Testimonial,
} from '../../API/interfaces';
import { PageStatus } from '../../enums';
import { redirectHome } from '../../utilities/path';
import { I18Context } from '../../shared/I18Context';
import i18n from '../../i18n';
import {
  AboutUs,
  SpecialitiesSlider,
  TestimonialSlider,
  TopProcedures,
  Work,
} from '../../shared';
import { Quote } from '../Quotes';
import { SearchBar, SearchByTreatmentCity } from '../../shared/Search';
import { HospitalImagesCarousel } from '../../shared/HospitalImagesCarousel';

import { DoctorsHome } from './DoctorsHome';
import { HospitalsHome } from './HospitalsHome';

import './Home.scss';

type Props = {
  featuredHospitals: FeaturedHospital[],
  featuredDoctors: FeaturedDoctor[],
  featuredSpecialities: FeaturedSpeciality[],
  featuredTestimonials: Testimonial[],
  featuredTreatments: FeaturedTreatment[],
  isLoading: boolean,
} & WithTranslation;

type State = {
  featuredHospitals: FeaturedHospital[],
  featuredDoctors: FeaturedDoctor[],
  featuredSpecialities: FeaturedSpeciality[],
  featuredTestimonials: Testimonial[],
  featuredTreatments: FeaturedTreatment[],
  status: PageStatus,
  error: string | null,
};

class Home extends Component<Props, State> {
  static contextType = I18Context;
  context!: ContextType<typeof I18Context>;

  constructor(props) {
    super(props);
    this.state = {
      featuredHospitals: this.props.featuredHospitals || [],
      featuredDoctors: this.props.featuredDoctors || [],
      featuredSpecialities: this.props.featuredSpecialities || [],
      featuredTreatments: this.props.featuredTreatments || [],
      featuredTestimonials: this.props.featuredTestimonials || [],
      status: PageStatus.None,
      error: null,
    };

    // this.fetch = this.fetch.bind(this);
  }

  render() {
    return (
      <div className="home" style={{ marginBottom: '80px' }}>
        <Helmet>
          <html lang={this.props.i18n.language}/>
          <title>
            {this.props.t('homeTitle')}
          </title>
          <meta
            name="description"
            content={this.props.t('homeDescription')}
          />
        </Helmet>

        <div className="main-banner">
          <div className="container inner-content">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-6 col-sm-7">
                <SearchBar/>

                <h1 className="mt-3">
                  {this.props.t(
                    'For quality healthcare at best price with free ' +
                      'logistics and languages assistance across India',
                  )}
                </h1>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-5">
                <HospitalImagesCarousel/>
              </div>
            </div>
          </div>
        </div>

        <Suspense>
          <SearchByTreatmentCity/>
        </Suspense>

        <AboutUs/>

        <Work/>

        <Quote/>

        <HospitalsHome hospitals={this.state.featuredHospitals || []}/>

        <DoctorsHome doctors={this.state.featuredDoctors || []}/>

        <SpecialitiesSlider
          specialities={this.state.featuredSpecialities || []}
        />

        <TopProcedures treatments={[]} />

        <Suspense>
          <TestimonialSlider
            testimonials={this.state.featuredTestimonials || []}
          />
        </Suspense>
      </div>
    );
  }
}

const WithTrans = withTranslation()(Home);

// @todo: To check behaviour on errors.
(WithTrans as any).getInitialProps = async ({ match }): Promise<any> => {
  try {
    const check = redirectHome(match.url, i18n.language);
    if (check) {
      return check;
    }

    // const data = await FeaturedAPI.featuredHome();

    return {
      featuredHospitals: [],
      featuredDoctors: [],
      featuredSpecialities: [],
      featuredTestimonials: [],
      featuredTreatments: [],
    };
  } catch (err: any) {
    console.error(err);
  }
};

export { WithTrans as Home };

export default WithTrans;
