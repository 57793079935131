import {Component} from 'react';
import {Link} from 'react-router-dom';
import {Trans, WithTranslation, withTranslation} from 'react-i18next';

import {FeaturedHospital} from '../../API/interfaces';
import {getFeaturedHospitals} from "../../utilities/helpers";
import {HospitalCardShort} from "../Hospitals/HospitalCardShort";

type Props = {
  hospitals: FeaturedHospital[],
} & WithTranslation;

type State = Record<string, never>;

class HospitalsHome extends Component<Props, State> {
  getHospitals(): {
    id: number,
    name: string,
    city: string,
    image: string,
  }[] {
    return getFeaturedHospitals(this.props.i18n.language);
  }

  render() {
    return (
        <>
          <section
              className="section-area section-sp1 blog-area hospitals-list"
          >
            <div className="container">
              <div className="heading-bx text-center">
                <h6 className="title-ext text-primary">
                  {this.props.t('Top Hospitals')}
                </h6>
                <h2 className="title">
                  <Trans i18nKey="indiaBestHospitalAreHere">
                    India&apos;s best hospitals are here
                  </Trans>
                </h2>
              </div>

              <div className="row featured-hospitals-home mb-3">
                {
                  this.getHospitals().map((hospital) => (
                      <div
                          className="col-6 col-md-4 col-lg-3 col-xl-2 mb-2 featured-hospitals-column">
                        <HospitalCardShort hospital={hospital}/>
                      </div>
                  ))
                }
              </div>

              <div className="d-flex justify-content-center">
                <Link
                    to={`/${this.props.i18n.language}/hospitals`}
                    className="btn btn-outline-primary"
                >
                  {this.props.t('View All')}
                </Link>
              </div>
            </div>
          </section>
        </>
    );
  }
}

const WithTrans = withTranslation()(HospitalsHome);

export {WithTrans as HospitalsHome};
