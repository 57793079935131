import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Carousel } from 'react-bootstrap';

import { LazyImageSuspense } from './LazyImageSuspense';

type Props = WithTranslation;
type State = Record<string, never>;

class HospitalImagesCarousel extends PureComponent<Props, State> {
  render() {
    return (
      <>
        <Carousel className="hospitals-carousel" indicators={false}>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://res.cloudinary.com/alfplus-com/image/upload/v1677862471/Alfplus.com/Hospics/ResizedImage/qrg_1.jpg"
              alt={this.props.t('QRG Hospital')}
            />
            <Carousel.Caption>
              <h5>
                {this.props.t('QRG Hospital')}
              </h5>
              <p>
                {this.props.t('Faridabad')}
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <LazyImageSuspense
              className="d-block w-100 lazy"
              src="https://res.cloudinary.com/alfplus-com/image/upload/v1581100335/Alfplus.com/Hospics/ResizedImage/Nanavati_Super_Speciality_Hospital_Mumbai.jpg"
              alt={this.props.t('Nanavati Super Speciality Hospital')}
            />

            <Carousel.Caption>
              <h5>
                {this.props.t('Nanavati Super Speciality Hospital')}
              </h5>
              <p>
                {this.props.t('Mumbai')}
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <LazyImageSuspense
              className="d-block w-100 lazy"
              src="https://res.cloudinary.com/alfplus-com/image/upload/v1549703948/Alfplus.com/Hospics/Fortis_HospitalsFortis_Hospital_Bannerghatta_Bangalore.jpg"
              alt={this.props.t('Fortis Hospital')}
            />

            <Carousel.Caption>
              <h5>
                {this.props.t('Fortis Hospital')}
              </h5>
              <p>
                {this.props.t('Bangalore')}
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <LazyImageSuspense
              className="d-block w-100 lazy"
              src="https://res.cloudinary.com/alfplus-com/image/upload/v1551283766/Alfplus.com/Hospics/manipal_hospitals_dwarka_n.jpg"
              alt={this.props.t('Manipal Hospital')}
            />

            <Carousel.Caption>
              <h5>
                {this.props.t('Manipal Hospital')}
              </h5>
              <p>
                {this.props.t('New Delhi')}
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <LazyImageSuspense
              className="d-block w-100 lazy"
              src="https://res.cloudinary.com/alfplus-com/image/upload/v1615017285/Alfplus.com/Hospics/zyd.jpg"
              alt={this.props.t('Zydus Hospital')}
            />

            <Carousel.Caption>
              <h5>
                {this.props.t('Zydus Hospital')}
              </h5>
              <p>
                {this.props.t('Ahmedabad')}
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <LazyImageSuspense
              className="d-block w-100 lazy"
              src="https://res.cloudinary.com/alfplus-com/image/upload/v1549703949/Alfplus.com/Hospics/Max_Super_Speciality_Hospital_Saket_New_Delhi.jpg"
              alt={this.props.t('Max Hospital')}
            />

            <Carousel.Caption>
              <h5>
                {this.props.t('Max Hospital')}
              </h5>
              <p>
                {this.props.t('Saket Delhi')}
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <LazyImageSuspense
              className="d-block w-100 lazy"
              src="https://res.cloudinary.com/alfplus-com/image/upload/v1549703947/Alfplus.com/Hospics/Lilavati_Hospital_and_Research_Center_Chennai_Madras.jpg"
              alt={this.props.t('Lilavati Hospital')}
            />

            <Carousel.Caption>
              <div className="fw-bold">
                {this.props.t('Lilavati Hospital')}
              </div>
              <p>
                {this.props.t('Mumbai')}
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <LazyImageSuspense
              className="d-block w-100 lazy"
              src="https://res.cloudinary.com/alfplus-com/image/upload/v1581100315/Alfplus.com/Hospics/ResizedImage/Continental_Hospitals_Hyderabad.jpg"
              alt={this.props.t('Continental Hospital')}
            />

            <Carousel.Caption>
              <h5>
                {this.props.t('Continental Hospital')}
              </h5>
              <p>
                {this.props.t('Hyderabad')}
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </>
    );
  }
}

const WithTrans = withTranslation()(HospitalImagesCarousel);

export { WithTrans as HospitalImagesCarousel };

export default WithTrans;