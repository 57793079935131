import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import { FeaturedDoctor } from '../../API/interfaces';
import { getFeaturedDoctors } from '../../utilities/helpers';
import { DoctorCardShortV2 } from '../Doctors/DoctorCardShortV2';

type Props = {
  doctors: FeaturedDoctor[],
} & WithTranslation;

type State = Record<string, never>;

class DoctorsHome extends PureComponent<Props, State> {
  getDoctors(): {
    id: number,
    name: string,
    image: string,
    hospitalName: string,
    speciality: string,
  }[] {
    return getFeaturedDoctors(this.props.i18n.language);
  }

  render() {
    return (
      <>
        <section className="section-area doctors-list">
          <div className="container">
            <div className="heading-bx text-center">
              <h6 className="title-ext text-primary">
                {this.props.t('Top Doctors')}
              </h6>
              <h2 className="title">
                {this.props.t('indiaBestDoctorAreHere')}
              </h2>
            </div>

            <div className="row featured-doctors-home">
              {
                this.getDoctors().map((doctor) => (
                  <div
                    className="col-6 col-md-4 col-lg-3 col-xl-2 mb-2 featured-doctors-cards"
                  >
                    <DoctorCardShortV2 doctor={doctor}/>
                  </div>
                ))
              }
            </div>

            <div className="d-flex justify-content-center">
              <Link
                to={`/${this.props.i18n.language}/doctors`}
                className="btn btn-primary light"
              >
                {this.props.t('View All')}
              </Link>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const WithTrans = withTranslation()(DoctorsHome);

export { WithTrans as DoctorsHome };
