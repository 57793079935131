import routes from '../routes';
import { Language } from '../enums';

export function isRouteWithLanguageValid(req, res, next): boolean {
  const hasRoute = routes.find((item) => {
    return !!item.path && item.path.includes(req.path.slice(3));
  });
  return !!hasRoute
}

export function injectLanguage(req, res, next): void {
  const splitRoute = req.path.split('/');
  if (splitRoute[1].length !== 2) {
    const hasRoute = routes.find((item) => {
      return !!item.path && item.path.includes(req.path);
    });
    if (hasRoute) {
      return res.redirect(`/${req.language || 'en'}${req.path}`);
    }
  }
  next();
}

export function redirectHome(url: string, lng: string): {
  statusCode?: number,
  redirectTo?: string,
} | null {
  const splitRoute = url.split('/');
  const hasRoute = routes.find((item) => {
    return !!item.path && item.path.includes(url);
  });
  const hasLang = [
    Language.uz,
    Language.en,
    Language.ar,
  ].includes(splitRoute[1] as Language);
  if (!hasRoute && !hasLang) {
    return { statusCode: 404 };
  }

  if (hasLang) {
    return null;
  }

  if (hasRoute) {
    return { redirectTo: `/${lng}${url}` };
  }

  return { statusCode: 404 };
}